<template>
    <div>
        <div class="headerbg">
            <b-container>
                <h1>常見問題</h1>
            </b-container>
        </div>
        <b-container>
            <div class="accordion mb-5" role="tablist">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1  d-none" role="tab">
                        <b-button block v-b-toggle.accordion-1 variant="link">會員相關</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-card-text>
                                <div class="qanda" v-for="(item, index) in List" :key="index">
                                    <div class="question">
                                        <b-icon icon="question-circle-fill" variant="danger"></b-icon>
                                        <span>{{ item.Title }}</span>
                                    </div>
                                    <div class="amswer">
                                        <b-icon icon="chat-dots-fill" variant="success"></b-icon>
                                        <div v-html="item.Content" style="white-space: break-spaces;"></div>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>

                <b-card no-body class="mb-1 d-none">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-2 variant="link">課程相關</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-card-text>
                                <div class="qanda">
                                    <div class="question">
                                        <b-icon icon="question-circle-fill" variant="danger"></b-icon> <span>問題</span>
                                    </div>
                                    <div class="amswer">
                                        <b-icon icon="chat-dots-fill" variant="success"></b-icon>答案
                                    </div>
                                </div>
                                <div class="qanda">
                                    <div class="question">
                                        <b-icon icon="question-circle-fill" variant="danger"></b-icon> <span>問題</span>
                                    </div>
                                    <div class="amswer">
                                        <b-icon icon="chat-dots-fill" variant="success"></b-icon>答案
                                    </div>
                                </div>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>

                <b-card no-body class="mb-1 d-none">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-3 variant="link">其他</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-card-text>
                                <div class="qanda">
                                    <div class="question">
                                        <b-icon icon="question-circle-fill" variant="danger"></b-icon> <span>問題</span>
                                    </div>
                                    <div class="amswer">
                                        <b-icon icon="chat-dots-fill" variant="success"></b-icon>答案
                                    </div>
                                </div>
                                <div class="qanda">
                                    <div class="question">
                                        <b-icon icon="question-circle-fill" variant="danger"></b-icon> <span>問題</span>
                                    </div>
                                    <div class="amswer">
                                        <b-icon icon="chat-dots-fill" variant="success"></b-icon>答案
                                    </div>
                                </div>
                                <div class="qanda">
                                    <div class="question">
                                        <b-icon icon="question-circle-fill" variant="danger"></b-icon> <span>問題</span>
                                    </div>
                                    <div class="amswer">
                                        <b-icon icon="chat-dots-fill" variant="success"></b-icon>答案
                                    </div>
                                </div>
                                <div class="qanda">
                                    <div class="question">
                                        <b-icon icon="question-circle-fill" variant="danger"></b-icon> <span>問題</span>
                                    </div>
                                    <div class="amswer">
                                        <b-icon icon="chat-dots-fill" variant="success"></b-icon>答案
                                    </div>
                                </div>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </b-container>
    </div>
</template>
<script>
    import { apiGetFaq } from '@/api/API.js';
    export default {
        name: 'QandA',
        data() {
            return {
                AllParam: this.$store.state.AllParam,
                List: [],
            };
        },
        mounted() {
            apiGetFaq(this.AllParam)
                .then((res) => {
                    this.List = res.data;
                })
                .catch((err) => {
                    console.error(err);
                    alert(err.response.data);
                });
            // let navBgSwitch=this._storeData('navBgSwitch')
            // if(this.IsNull(navBgSwitch)!=''){
            //    navBgSwitch(false)
            // }
        },
    };
</script>
