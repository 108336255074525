<template>
    <div>
        <div class="headerbg">
            <b-container>
                <h1>關於我們</h1>
            </b-container>
        </div>
        <b-container>
            <div class="about" v-html="content"></div>
        </b-container>
    </div>
</template>
<script>
    import { apiGetInformation } from '@/api/API.js';
    export default {
        name: 'page-footer',
        components: {},
        data() {
            return {
                content: '',
            };
        },
        created() {
            apiGetInformation('about')
                .then((res) => {
                    this.content = res.data.Content;
                })
                .catch((err) => {
                    console.log(err);
                    alert(err.respone.data);
                });
        },
        mounted() {},
        methods: {},
    };
</script>
